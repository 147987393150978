<template>
    <div class="flex h-full flex-col">
        <partial-chat-items :room="room" full-width class="gap-6 px-8 py-6" />

        <div class="border-t px-6 py-4">
            <form class="relative flex gap-3" @submit.prevent="sendMessage">
                <form-rich-text-editor
                    v-model="message"
                    :error-message="errorMessage"
                    hide-message
                    editor-container-class="editor-chat-class"
                    autofocus
                />
                <div class="absolute bottom-2.5 right-2.5">
                    <base-button
                        type="submit"
                        class="p-3"
                        icon="line-icons:communication:send-01"
                        :has-padding="false"
                        :disabled="disabledSend"
                        :loading="loadingSend"
                    />
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    room: {
        type: Object,
        required: true,
    },
})

const emitter = useEmitter()
const { message, errorMessage, disabledSend, loadingSend, onSendMessage } =
    useMessage(props.room)

const sendMessage = () => {
    onSendMessage().then((message) => {
        emitter.emit('sent-message', {
            room_id: props.room.id,
            message,
        })
    })
}
</script>
